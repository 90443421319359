export default {
  "development": {
    "getHeroes": "/api/heroes.json",
    "getHero": "/api/hero.json",
    "getAbout": "/api/about.json"
  },
  "production": {
    "getHeroes": "/api/heroes/",
    "getHero": "/api/hero/",
    "getAbout": "/api/about/"
  }
}
