import { Link } from 'react-router-dom'

function Nav() {
  return (
    <ul className="header__nav">
      <li className="header__nav-item">
        <Link 
          to="/about"
          className="header__nav-link"
        >
          <span className="header__nav-text">
            о проекте
          </span>
          <svg className="header__nav-icon" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="24" height="24" rx="12" stroke="white"/>
            <rect x="12" y="11" width="1" height="6" rx="0.5" fill="white"/>
            <rect width="1" height="1" rx="0.5" transform="matrix(1 0 0 -1 12 9)" fill="white"/>
          </svg>
        </Link>
      </li>
    </ul>
  )
}

export default Nav;
