import { Link } from 'react-router-dom'

function Button() {
  return (
    <Link 
      to="/"  
      className="header__back"
    >
      <svg viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 1.91421C5 1.02331 3.92286 0.577142 3.29289 1.20711L0.707106 3.79289C0.316582 4.18342 0.316583 4.81658 0.707107 5.20711L3.29289 7.79289C3.92286 8.42286 5 7.97669 5 7.08579L5 1.91421Z" fill="white"/>
      </svg>
      К звездам
    </Link>
  )
}

export default Button
