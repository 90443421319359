function Preloader() {
  return (
    <div className="preloader">
      <div className="preloader__spinner">
        <svg className="preloader__svg" viewBox="0 0 66 63.5">
          <path className="preloader__path" strokeWidth="4" d="M34.3 8.2l5 20h20l-15 10 6 20-16-12-16 12 6-20-17-10h22z" fill="none" strokeLinecap="round"></path>
        </svg>
      </div>
    </div>
  )
}

export default Preloader;
