import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import GalleryImg from "./GalleryImg";
import Arrow from "../heroes/Arrow";

function GallerySwiper({index, galleries}) {
  return (
    <div className="gallery__swiper-wrap">
      <Swiper
        modules={[Navigation]}
        className="gallery__swiper"
        slidesPerView={1}
        initialSlide={index}
        navigation={{
          nextEl: '.gallery__arrow--next',
          prevEl: '.gallery__arrow--prev'
        }}
      >
        {galleries.map((gallery, key) =>
          <SwiperSlide key={key}>
            <GalleryImg imgSrc={gallery.img} imgAlt={gallery.alt}/>
          </SwiperSlide>
        )}
      </Swiper>
        <div>
          <button type="button" className="gallery__arrow gallery__arrow--prev">
            <Arrow />
          </button>
          <button type="button" className="gallery__arrow gallery__arrow--next">
            <Arrow />
          </button>
        </div>
    </div>
  )
}

export default GallerySwiper
