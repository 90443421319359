import {formatDate} from '../../../helpers/helpers'

function CupAward({award}) {
  return (
    <li 
      className="hero__awards-item hero__awards-item--cup"
    >
      <picture className="hero__awards-cup">
        <img src={award.cup} alt="" />
      </picture>
      <div className="hero__awards-text">
        <div className="hero__awards-name">
          {award.name}
        </div>
        <div className="hero__awards-date"  dangerouslySetInnerHTML={{__html: formatDate(award.date)}}>
        </div>
      </div>
    </li>
  )
}

export default CupAward
