import React, {useState, useEffect} from "react";
import Header from '../сomponents/header/Header'
import HeaderButton from '../сomponents/header/Button'
import Preloader from '../сomponents/preloader/Preloader'
import background from "../img/about/background.png"
import useFetching from "../hooks/useFetching"
import API from '../API/API'

function About() {
  const [about, setAbout] = useState({})
  const [getAbout, isLoading] = useFetching(API.getAbout, setAbout)

  useEffect(() => {
    getAbout()
  }, [])

  return (
    <div className="page">
      <Header>
        <HeaderButton />
      </Header>
      <div className="page__inner">
        <div 
          className="page__background"
        >
          <img src={background} alt="" />
        </div>
        {isLoading ? <Preloader /> : 
          <div className="about">
            <div className="about__title">
              {about.title}
            </div>
            <div className="about__text">
              {about.text}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default About
