import React from "react"
import CupAward from "./CupAward"
import IconAward from "./IconAward"
import TextAward from "./TextAward"

function Awards({awards}) {
  const awardTypes = {
    'cup': CupAward,
    'icon': IconAward,
    'text': TextAward
  }

  return (
    <ul className="hero__awards">
      {
        awards.map((award, index) => 
          React.createElement(awardTypes[award.type], {
            award: award,
            key: index
          })
        )
      }
    </ul>
  )
}

export default Awards
