import React, {useState, useEffect} from "react"
import {Link, useParams} from 'react-router-dom'
import Header from '../сomponents/header/Header'
import HeaderButton from '../сomponents/header/Button'
import Awards from "../сomponents/hero/awards/Awards"
import Preloader from '../сomponents/preloader/Preloader'
import useFetching from "../hooks/useFetching"
import API from '../API/API'
import ArrowPage from "../сomponents/hero/awards/ArrowPage"
import Gallery from "../сomponents/gallery/Gallery"

function Hero() {
  const [hero, setHero] = useState({})
  const params = useParams()
  const [getHero, isLoading] = useFetching(async () => API.getHero(params.id), setHero)
  useEffect(() => {
      getHero()
  }, [params.id])


  return (
    <div className="page page--hero">
      <Header>
        <HeaderButton />
      </Header>
      <div className="page__inner page__inner--hero">
          {hero.prevPage ?
              <Link to={`/stars/${hero.prevPage}`} className="hero__btn hero__prev">
                  <ArrowPage/>
              </Link> : ''
          }
        {isLoading ? <Preloader /> :
          <div className="hero">
            {Object.keys(hero).length ?
              <div key={hero.id} className="hero__inner">
                {hero.background &&
                  <div
                    className="page__background"
                  >
                    <img src={hero.background} alt="" />
                  </div>
                }
                <div className="hero__person">
                  <div
                    className="hero__person-overlay"
                    style={{
                      maskImage: `url(${hero.photo})`,
                      WebkitMaskImage: `url(${hero.photo})`
                    }}
                  >
                  </div>
                  <div className="hero__person-text">
                    <div className="hero__name">
                      { hero.name }
                        <br />
                      { hero.surname }
                    </div>
                    <div className="hero__position">
                      {hero.position}
                    </div>
                  </div>
                  <img src={hero.photo} alt="" />
                </div>
                <div className="hero__info">
                  <div className="hero__name hero__name--desktop">
                    { hero.name }
                      <br />
                    { hero.surname }
                  </div>
                  <div className="hero__position hero__position--desktop">
                    {hero.position}
                  </div>
                  {hero.awards?.length ? <Awards awards={hero.awards} /> : ''}
                  <div className="hero__birth">
                    {hero.birthday ? 'Родился' : ''} { hero.birthday }
                  </div>
                  <div className="hero__text" dangerouslySetInnerHTML={{__html: hero.description}}>
                  </div>
                  {hero.galleries?.length?
                    <div className="hero__gallery gallery">
                      <Gallery galleries={hero.galleries}/>
                    </div> : ''
                  }
                </div>
              </div> : ''
            }
          </div>
        }
          {hero.nextPage ?
              <Link to={`/stars/${hero.nextPage}`} className="hero__btn hero__next">
                  <ArrowPage/>
              </Link> : ''
          }
      </div>
    </div>
  )
}

export default Hero
