import URLS from './URLS'
const ENV_URLS = URLS[process.env.REACT_APP_ENV]

export default class API {
  static async getHeroes() {
    return fetch(ENV_URLS.getHeroes)
  }

  static async getHero(id) {
    return fetch(`${ENV_URLS.getHero}?id=${id}`)
  }

  static async getAbout() {
    return fetch(`${ENV_URLS.getAbout}`)
  }
}
