function ArrowPage() {
    return (
        <svg width="25" height="46" viewBox="0 0 25 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6" filter="url(#filter0_b_1125_211)">
                <path d="M22.2929 2.12132C22.9229 1.49135 24 1.93752 24 2.82843L24 43.1716C24 44.0625 22.9229 44.5086 22.2929 43.8787L2.12132 23.7071C1.73079 23.3166 1.73079 22.6834 2.12132 22.2929L22.2929 2.12132Z" stroke="white" strokeWidth="2"/>
            </g>
            <defs>
                <filter id="filter0_b_1125_211" x="-29.1714" y="-29.1755" width="84.1714" height="104.351" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="15"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1125_211"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1125_211" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export default ArrowPage;
