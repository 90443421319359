function TextAward({award}) {
  return (
    <li 
      className="hero__awards-item"
    >
      <div className="hero__awards-number">
        {award.number}
      </div>
      <div className="hero__awards-name">
        {award.name}
      </div>
    </li>
  )
}

export default TextAward
